.navbar {
  background-color: #040303;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 1rem;
  margin-top: 0.5rem;
  font-size: 1.8rem;
  background: none;
}

.nav-menu {
  background-color: #040303e5;
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 999;
}

.nav-menu.active {
  left: 0;
  z-index: 999;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 10px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f3f3f3;
  font-size: 20px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: rgba(0, 0, 0, 0.555);
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #040303;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}
