body {
  background-color: black;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
select,
.title,
.text,
.next,
.previous,
.footer,
.rhap_play-pause-button,
.devices {
  color: #f3f3f3;
}
a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Verdana, sans-serif;
  font-weight: normal;
}
/* Home Page */

.home_container,
.about_container {
  position: absolute;
  background: url("https://res.cloudinary.com/hyl31wapk/image/upload/v1615464649/home_yu4ovc_1_fleymr_ruym0b.webp")
    rgba(0, 0, 0, 0.6);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  background-blend-mode: multiply;
}
.home_container {
  width: 100%;
  min-height: 90%;
}
.about_container {
  width: 100%;
  min-height: 110%;
}
.home_card1,
.home_card2,
.home_cardQR {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.12);
  text-align: center;
  padding: 10px;
}
.home_card1 {
  position: relative;
  margin: 30px 10px 10px 10px;
  padding-bottom: 20px;
}
.home_cardQR {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.QRicon {
  padding: 10px 0 0 0;
  font-size: 52px;
}

.device_botom {
  position: absolute;
  bottom: 60px;
  right: 0;
  left: 0;
  margin: 10px;
}
#logoags {
  height: 40px;
  width: 262px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
}
.logo2 {
  height: 125px;
  width: 325px;
  position: relative;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -5%);
}
.devices {
  font-size: 25px;
  padding-top: 15px;
}
img {
  margin-top: 40px;

}
@media only screen and (min-width: 300px){
  .home_card1,
  .home_card2,
  .home_card,
  .about_card1,
  .about_card2,
  .about_card3,
  .home_cardQR {
    font-size: 12px;
    text-align: center;
    padding: 8px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.12);
  }
  .devices {
    font-size: 20px;
    padding-top: 10px;
  }

.QRicon {
  padding: 10px 0 0 0;
  font-size: 32px;
}

}
/* Home responsive */
@media only screen and (min-width: 900px) {
  .home_card1,
  .home_card,
  .about_card1,
  .about_card2 {
    
    font-size: 18px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.12);
  }
  #notFound {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
  }

  .home_card1 {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -10%);
    padding: 25px;
  }
  .home_cardQR {
    display: none;
  }

  .logo2 {
    height: 125px;
    width: 325px;
    position: relative;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -5%);
  }

  .about_card1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -5%);
    width: 550px;
  }
  .about_card2 {
    width: 550px;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -10%);
  }
  .about_card3 {
    width: 550px;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -10%);
  }
  .next {
    right: 435px !important;
  }
  .previous {
    left: 435px !important;
  }
  .device_botom {
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translate(-50%, -80%);
    margin: 10px;
  }
  img {
    margin-top: 80px;
  }
  .home_container{
    position: fixed;
  }
  .home_container,
  .about_container {
    width: 100%;
    min-height: 100%;
    background: url("https://res.cloudinary.com/hyl31wapk/image/upload/v1615465425/homedes_ie1ppz_vo6txi.webp")
      rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    background-blend-mode: multiply;
  }
  div.sticky {
    position: fixed;
    width: 100%;
    background: url("https://res.cloudinary.com/hyl31wapk/image/upload/v1615465631/museum3_kwvbjg_jyypai.webp")
      rgba(0, 0, 0, 0.85) !important;
    background-repeat: no-repeat;
    background-position: center !important;
    background-blend-mode: multiply;
  }
  .museum-description {
    line-height: 35px;
    width: 50%;
    margin: 0px auto;
    text-align: center;
    justify-content: center;
  
  }
}

/* about page */
.about_card1 {
  position: relative;
  margin: 15px 10px 10px 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.12);
  text-align: center;
  padding: 10px;
}
.about_card2 {
  position: relative;
  margin: 10px 10px 10px 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.12);
  text-align: center;
  padding: 10px;
}
.about_card3 {
  position: relative;
  margin: 10px 10px 70px 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.12);
  text-align: center;
  padding: 10px;
}
/* Museum Page */
.museum {
  width: 100%;
  height: auto;
  text-align: center;
  justify-content: center;
}
/* Museum Title*/
.name {
  font-size: .4rem;
  text-align: center;
  display: flex;
  padding-top: 10px;
  justify-content: center;
}

/*Museum Data */
.museum_description {
  width: 93%;
  margin: 0 auto;
  margin-bottom: 80px;
  padding-left: 5px;
  padding-right: 5px;
  height: auto;
  text-align: left;
  justify-content: center;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.12);
}

.title {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.3rem;
  padding-top: 10px;
}

.text {
  margin-top: 1rem;
  font-size: 1.1rem;
  white-space: pre-line;
  padding-left: 10px;
  padding-bottom: 90px;
}
.subtitle {
  margin-top: .2rem;
  text-align: center;
  font-size: 1.2rem;
  padding-top: 10px;
}
/*
.play {
  width: 200px;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.next {
  display: inline-block;
  right: 55px;
  top: 167px;
  font-size: 65px;
}
.previous {
  left: 55px;
  top: 167px;
  font-size: 65px;
}
.next,
.previous {
  border: none;
  position: absolute;
  background: transparent;
  /*text-shadow: 0px 3px 9px #0665c3;*/
}
.next:active,
.previous:active,
.rhap_play-pause-button:active {
  transform: scale(1.28);
}

select {
  margin-top: 10px;
  font-size: 3rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
}

/* Footer*/
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #040303;
  padding: 16px;
  font-size: 8px;
  text-align: center;
  box-shadow: 10px 1px 60px rgb(24, 23, 23);
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: black;
  padding: 0px;
  font-size: 20px;
  overflow: auto;
  z-index: 1;
  background: url("https://res.cloudinary.com/hyl31wapk/image/upload/v1615465631/museum3_kwvbjg_jyypai.webp")
    rgba(0, 0, 0, 0.65);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-blend-mode: multiply;
}

.rhap_additional-controls,
.rhap_rewind-button,
.rhap_forward-button,
.rhap_volume-controls,
.rhap_current-time,
.rhap_time,
.rhap_total-time {
  display: none;
}
.rhap_play-pause-button {
  background-color: transparent;
  border: none;
  font-size: 82px;
  padding-top: 20px;
}
#language select {
  border: none;
  position: absolute;
  left: 80%;
  font-size: 2.5rem;
  margin-top: -52px;
  background-color: transparent;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3), 0 0px 0px rgba(0, 0, 0, 0.22);
  padding: 1px 2px 1px 2px;
}

@media only screen and (min-width: 900px) {
  #language select {
    border: none;
    position: absolute;
    left: 93%;
    margin-top: -56px;
  }

  *:focus {
    outline: none;
  }
  .rhap_progress-bar {
    width: 50% !important;
    margin-left: 25%;
  }
  .videoPlayer {
    margin-left: 39%;
  }
}

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(50px + 1%);
  cursor: pointer;
  -webkit-user-select: none;
}
*:focus {
  outline: none;
}
.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  margin-top: 40px;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: #f3f3f3;
  border-radius: 2px;
}


.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: #05a4fd;
  border-radius: 2px;
}
#notFound {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
}

.log {
  
  color: white;
  font-size: 18px;
  border-bottom: 1px solid white!important;
  padding-top: 20px;
  background: transparent;
  border: none;
  outline: none;
  
}
input:focus {
  outline: none !important;
}
.form-log {
  margin-top: 50px;
  

}

.btn-log {
  background-color: #05a4fd;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px 15px 10px 15px;
  margin-top: 20px;
}
